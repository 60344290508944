import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import Datetime from "react-datetime";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  updateItem,
  ItemRequestStatus,
  getList,
  getItem,
} from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import SaleOrder, {
  SaleOrderFormItem,
  SaleOrderFormItemSize,
  SaleOrderItem,
  newSaleOrder,
  saleOrderAPIReceivedToTableFormat,
  saleOrderTableFormatToAPIReceived,
} from "../../models/sale-order";
import ClientSelect from "../client/ClientSelect";
import Client from "../../models/client";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import {
  UniqueListItem,
  listToUniqueListItems,
  newUniqueListItem,
  uniqueListToListItems,
} from "../../models/unique-list-item";
import SaleOrderItemForm from "./SaleOrderItemForm";
import { saleOrderIsEditable } from "../../utils/sale-orders";
import { standardSizes } from "../../utils/constants/sizes";
import Variant from "../../models/variant";

interface SaleOrderFormProps {
  initialSaleOrder?: SaleOrder;
  initialErrors?: Errors;
  allowUsingSuperWholesale: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = [
  "Prenda",
  "Color",
  "Precio Unit.",
  "Subtotal",
  "Cant. Total",
];

const SaleOrderForm: React.FC<SaleOrderFormProps> = ({
  initialSaleOrder,
  initialErrors,
  allowUsingSuperWholesale,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingSaleOrder, setEditingSaleOrder] = useState<SaleOrder>(
    initialSaleOrder ? initialSaleOrder : newSaleOrder()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [editable, setEditable] = useState(true);
  const [orderDate, setOrderDate] = useState<string | moment.Moment>("");
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [saleOrderItems, setSaleOrderItems] = useState<
    UniqueListItem<SaleOrderFormItem>[]
  >([]);

  const [amountTobeAdded, setAmountToBeAdded] = useState<number>(1);
  const [barcodeTobeAdded, setBarcodeToBeAdded] = useState<string>("");

  const barcodeInputRef = useRef<HTMLInputElement>(null);

  const onOrderDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    setOrderDate(value);

    if (moment.isMoment(value)) {
      setEditingSaleOrder({
        ...editingSaleOrder,
        orderDate: value.toISOString(),
      });
    } else if (value !== undefined && value !== "") {
      setEditingSaleOrder({
        ...editingSaleOrder,
        orderDate: value,
      });
    }
  };

  const onClientChange = (newClient: Client | null) => {
    setEditingSaleOrder((editingItem) => {
      return {
        ...editingItem,
        clientId: newClient !== null ? newClient.id : undefined,
        clientName: newClient !== null ? newClient.name : undefined,
        client: newClient !== null ? newClient : undefined,
      };
    });
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSaleOrder({
      ...editingSaleOrder,
      obs: e.target.value,
    });
  };

  const onAddItemClick = useCallback(() => {
    const newSaleOrderItems = [
      ...saleOrderItems,
      newUniqueListItem<SaleOrderFormItem>({}),
    ];

    setSaleOrderItems(newSaleOrderItems);
    generateTableHeaders(newSaleOrderItems);
  }, [saleOrderItems]);

  const onSaleOrderFormItemEntryChange = useCallback(
    (saleOrderFormItem: UniqueListItem<SaleOrderFormItem>) => {
      let newSaleOrderItems = [...saleOrderItems];
      const index = newSaleOrderItems.findIndex((item) => {
        return item.uuid === saleOrderFormItem.uuid;
      });

      if (index === -1) {
        setSaleOrderItems(newSaleOrderItems);
        return;
      }

      if (saleOrderFormItem.item.skipProcessing === true) {
        saleOrderFormItem.item.skipProcessing = false;
        newSaleOrderItems[index] = saleOrderFormItem;
        setSaleOrderItems(newSaleOrderItems);
        return;
      }

      if (
        saleOrderFormItem.item.colorId !== newSaleOrderItems[index].item.colorId
      ) {
        const sameProductAndColorIndex = newSaleOrderItems.findIndex((item) => {
          return (
            item.item.clothingProductId ===
              saleOrderFormItem.item.clothingProductId &&
            item.item.colorId === saleOrderFormItem.item.colorId
          );
        });
        if (sameProductAndColorIndex !== -1) {
          warningAlert(
            "Este producto en este color ya fue agregado previamente a la lista"
          );
        }
      }

      newSaleOrderItems[index] = saleOrderFormItem;

      const productId = saleOrderFormItem.item.clothingProductId;
      if (productId !== undefined) {
        const productTotalSum = newSaleOrderItems
          .filter((entry) => entry.item.clothingProductId === productId)
          .reduce(
            (total, entry) =>
              total +
              (entry.item.sizes
                ? entry.item.sizes.reduce(
                    (subtotal, size) => subtotal + (size.amountRequired || 0),
                    0
                  )
                : 0),
            0
          );

        const updatedNewSaleOrderItems = newSaleOrderItems.map((entry) => {
          if (entry.item.clothingProductId === productId) {
            let unitPrice: number | undefined;
            let totalPrice: number | undefined;
            let amount =
              entry.item.sizes?.reduce(
                (sum, size) => sum + (size.amountRequired || 0),
                0
              ) || 0;
            if (entry.item.hasCustomPrice && entry.item.unitPrice) {
              return {
                ...entry,
                item: {
                  ...entry.item,
                  totalCodeAmount: entry.item.unitPrice,
                  unitPrice: entry.item.unitPrice * amount,
                  totalPrice: totalPrice,
                },
              };
            }
            if (
              entry.item.clothingProduct &&
              entry.item.clothingProduct.wholesaleThreshold &&
              entry.item.clothingProduct.wholesalePrice &&
              productTotalSum >=
                entry.item.clothingProduct.wholesaleThreshold &&
              entry.item.hasCustomPrice === undefined
            ) {
              unitPrice = entry.item.clothingProduct.wholesalePrice;

              totalPrice = amount
                ? entry.item.clothingProduct.wholesalePrice * amount
                : undefined;
            } else if (
              entry.item.clothingProduct?.price !== undefined &&
              entry.item.hasCustomPrice === undefined
            ) {
              unitPrice = entry.item.clothingProduct.price;
              totalPrice = amount
                ? entry.item.clothingProduct.price * amount
                : undefined;
            } else {
              unitPrice = entry.item.unitPrice;
              totalPrice = entry.item.totalPrice;
            }

            return {
              ...entry,
              item: {
                ...entry.item,
                totalCodeAmount: productTotalSum,
                unitPrice: unitPrice,
                totalPrice: totalPrice,
              },
            };
          } else {
            return entry;
          }
        });
        newSaleOrderItems = [...updatedNewSaleOrderItems];
      }
      setSaleOrderItems(newSaleOrderItems);

      const newTotal = newSaleOrderItems.reduce(
        (total, formItem) => total + (formItem.item.totalPrice || 0),
        0
      );
      let calculatedTotalQuantity = calculateTotalQuantity(newSaleOrderItems);
      setTotalQuantity(calculatedTotalQuantity);

      generateTableHeaders(newSaleOrderItems);

      setEditingSaleOrder({ ...editingSaleOrder, totalPrice: newTotal });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saleOrderItems]
  );

  const calculateTotalQuantity = (
    tableItems: UniqueListItem<SaleOrderFormItem>[]
  ): number => {
    return tableItems.reduce(
      (total, entry) =>
        total +
        (entry.item.sizes?.reduce(
          (total, entry) => total + (entry.amountRequired || 0),
          0
        ) || 0),
      0
    );
  };

  const onSaleOrderFormItemEntryDelete = useCallback(
    (entry: UniqueListItem<SaleOrderFormItem>) => {
      let newSaleOrderItems = [...saleOrderItems];
      const index = newSaleOrderItems.findIndex((item) => {
        return item.uuid === entry.uuid;
      });

      if (index === -1) {
        setSaleOrderItems(newSaleOrderItems);
      }

      const productId = entry.item.clothingProductId;

      newSaleOrderItems.splice(index, 1);

      if (productId !== undefined) {
        const productTotalSum = newSaleOrderItems
          .filter((entry) => entry.item.clothingProductId === productId)
          .reduce(
            (total, entry) =>
              total +
              (entry.item.sizes
                ? entry.item.sizes.reduce(
                    (subtotal, size) => subtotal + (size.amountRequired || 0),
                    0
                  )
                : 0),
            0
          );

        const updatedNewSaleOrderItems = newSaleOrderItems.map((entry) => {
          if (entry.item.clothingProductId === productId) {
            let unitPrice: number | undefined;
            let totalPrice: number | undefined;
            let amount = entry.item.sizes?.reduce(
              (sum, size) => sum + (size.amountRequired || 0),
              0
            );
            if (
              entry.item.clothingProduct &&
              entry.item.clothingProduct.wholesaleThreshold &&
              entry.item.clothingProduct.wholesalePrice &&
              productTotalSum >=
                entry.item.clothingProduct.wholesaleThreshold &&
              entry.item.hasCustomPrice === undefined
            ) {
              unitPrice = entry.item.clothingProduct.wholesalePrice;

              totalPrice = amount
                ? entry.item.clothingProduct.wholesalePrice * amount
                : undefined;
            } else if (
              entry.item.clothingProduct?.price !== undefined &&
              entry.item.hasCustomPrice === undefined
            ) {
              unitPrice = entry.item.clothingProduct.price;
              totalPrice = amount
                ? entry.item.clothingProduct.price * amount
                : undefined;
            } else {
              unitPrice = entry.item.unitPrice;
              totalPrice = entry.item.totalPrice;
            }

            return {
              ...entry,
              item: {
                ...entry.item,
                totalCodeAmount: productTotalSum,
                unitPrice: unitPrice,
                totalPrice: totalPrice,
              },
            };
          } else {
            return entry;
          }
        });
        newSaleOrderItems = [...updatedNewSaleOrderItems];
      }

      setSaleOrderItems(newSaleOrderItems);

      const newTotal = newSaleOrderItems.reduce(
        (total, formItem) => total + (formItem.item.totalPrice || 0),
        0
      );
      let calculatedTotalQuantity = calculateTotalQuantity(newSaleOrderItems);
      setTotalQuantity(calculatedTotalQuantity);
      generateTableHeaders(newSaleOrderItems);
      setEditingSaleOrder({ ...editingSaleOrder, totalPrice: newTotal });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [saleOrderItems]
  );

  const generateTableHeaders = (
    orderItems: UniqueListItem<SaleOrderFormItem>[]
  ) => {
    const uniqueSizeNames: string[] = [];

    orderItems.forEach((item) => {
      if (item.item.sizes) {
        item.item.sizes.forEach((size) => {
          const sizeName = size?.sizeName;
          if (sizeName && !uniqueSizeNames.includes(sizeName)) {
            uniqueSizeNames.push(sizeName);
          }
        });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      // Check if both sizes are in the standard sizes list
      const aIndex = standardSizes.indexOf(a);
      const bIndex = standardSizes.indexOf(b);

      // If both sizes are found in the standard sizes list, sort by their order in the list
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }

      // If only one of the sizes is in the standard sizes list, prioritize the one that is
      if (aIndex !== -1) return -1; // a comes first
      if (bIndex !== -1) return 1; // b comes first

      // Otherwise, sort them by string length and numeric value as before
      const keyLengthDiff = a.length - b.length;
      if (keyLengthDiff !== 0) {
        return keyLengthDiff;
      }

      return parseInt(a) - parseInt(b);
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames, "Acciones"]);
  };

  const getSaleOrderItems = async () => {
    if (initialSaleOrder === undefined || initialSaleOrder.id === undefined) {
      setSaleOrderItems([]);
      return [];
    }
    const saleOrderIdStr = initialSaleOrder.id.toString();
    if (saleOrderIdStr === undefined) {
      setSaleOrderItems([]);
      return [];
    }
    const limit = 5000;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", company.id!.toString());
    additionalParams.set("sale_order_id", saleOrderIdStr);
    const itemsStatus = await getList<SaleOrderItem>(
      `/sale_orders/items/`,
      limit,
      offset,
      additionalParams
    );
    if (itemsStatus.status === SUCCESS) {
      if (itemsStatus.data !== undefined) {
        const items = listToUniqueListItems(
          saleOrderAPIReceivedToTableFormat(itemsStatus.data.items)
        );
        setSaleOrderItems(items);
        let calculatedTotalQuantity = calculateTotalQuantity(items);
        setTotalQuantity(calculatedTotalQuantity);
        generateTableHeaders(items);
      }
    } else {
      setSaleOrderItems([]);
    }
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendSaleOrder = {
      ...editingSaleOrder,
      companyId: company.id,
      status: "pending",
      deliveryStatus: "pending",
      paymentStatus: "pending",
      pendingPrice: 0,
      paidPrice: 0,
      saleOrderItems: saleOrderTableFormatToAPIReceived(
        uniqueListToListItems(saleOrderItems)
      ),
    };
    let requestPromise: Promise<ItemRequestStatus<SaleOrder>>;
    if (editingSaleOrder.id === undefined) {
      requestPromise = createItem<SaleOrder>(
        "/sale_orders/create/",
        toSendSaleOrder
      );
    } else {
      requestPromise = updateItem<SaleOrder>(
        `/sale_orders/${toSendSaleOrder.id}/`,
        toSendSaleOrder
      );
    }

    const saleOrderStatus = await requestPromise;

    if (saleOrderStatus.status !== SUCCESS) {
      if (saleOrderStatus.errors !== undefined) {
        setErrors(saleOrderStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (saleOrderStatus.detail !== undefined) {
        message = saleOrderStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };
  const clearForm = () => {
    setEditingSaleOrder(newSaleOrder());
    setSaleOrderItems([]);
    setTotalQuantity(0);
    generateTableHeaders([]);
    setBarcodeToBeAdded("");
    setAmountToBeAdded(1);
    setEditable(true);
    if (barcodeInputRef.current !== null) {
      barcodeInputRef.current.focus();
    }
    setOrderDate("");
  };

  const onBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarcodeToBeAdded(e.target.value);
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmountToBeAdded(Number(e.target.value));
  };

  const onBarcodeSend = async () => {
    if (barcodeTobeAdded === "") {
      errorAlert("Debe Completar el campo codigo de barras");
      return;
    }
    await fetchVariantFromBarcode(barcodeTobeAdded);
  };

  const fetchVariantFromBarcode = async (barcode: string) => {
    barcode = barcode.trim().replace(/[\/\s]/g, "");
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    const variantStatus = await getItem<Variant>(
      `/product_variants/barcode/${barcode}/`,
      additionalParams
    );
    if (variantStatus.status === SUCCESS) {
      if (variantStatus.data === undefined) {
        errorAlert("Ocurrió un error");
        setBarcodeToBeAdded("");
        setAmountToBeAdded(1);
        return;
      }
      processVariantToList(variantStatus.data, amountTobeAdded);
    } else {
      const message = variantStatus.detail
        ? variantStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }

    setBarcodeToBeAdded("");
    setAmountToBeAdded(1);
    if (barcodeInputRef.current !== null) {
      barcodeInputRef.current.focus();
    }
  };

  const onBarcodeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && barcodeTobeAdded !== "") {
      onBarcodeSend();
    }
  };

  const processVariantToList = useCallback(
    async (variant: Variant, amountReceived: number) => {
      const existingItemIndex = saleOrderItems.findIndex(
        (entry) =>
          entry.item.clothingProductId === variant.productId &&
          entry.item.colorId === variant.colorId
      );

      let newSaleOrderItems = [...saleOrderItems];

      const productTotalSum =
        newSaleOrderItems
          .filter((entry) => entry.item.clothingProductId === variant.productId)
          .reduce(
            (total, entry) =>
              total +
              (entry.item.sizes
                ? entry.item.sizes.reduce(
                    (subtotal, size) => subtotal + (size.amountRequired || 0),
                    0
                  )
                : 0),
            0
          ) + amountReceived;

      if (existingItemIndex !== -1) {
        const existingItem = newSaleOrderItems[existingItemIndex];

        const updatedSizes: SaleOrderFormItemSize[] =
          existingItem.item.sizes?.map((size) => {
            if (size.sizeId === variant.sizeId) {
              return {
                ...size,
                amountRequired: (size.amountRequired || 0) + amountReceived,
              };
            }
            return size;
          }) || [];

        newSaleOrderItems[existingItemIndex] = {
          ...existingItem,
          item: {
            ...existingItem.item,
            totalCodeAmount: productTotalSum,
            sizes: updatedSizes,
          },
        };
      } else {
        let newSizes: SaleOrderFormItemSize[] =
          await fetchAvailableSizesForEntry(
            variant.productId!,
            variant.colorId!
          );

        if (newSizes.length === 0) {
          return;
        }

        const firstSizeVariant = newSizes[0].variant;

        newSizes = newSizes.map((size) => {
          if (size.sizeId === variant.sizeId) {
            return {
              ...size,
              amountRequired: (size.amountRequired || 0) + amountReceived,
            };
          }
          return size;
        });

        const newItem = newUniqueListItem<SaleOrderFormItem>({
          companyId: firstSizeVariant?.companyId,
          clothingProductId: firstSizeVariant?.productId,
          clothingProduct: firstSizeVariant?.product,
          productCodeIdentifier: firstSizeVariant?.productCodeIdentifier,
          productName: firstSizeVariant?.productName,
          colorName: firstSizeVariant?.color,
          color: firstSizeVariant?.colorObject,
          colorId: firstSizeVariant?.colorId,
          totalCodeAmount: productTotalSum,
          sizes: newSizes,
        });
        newSaleOrderItems = [...newSaleOrderItems, newItem];
      }

      const updatedNewSaleOrderItems = newSaleOrderItems.map((entry) => {
        if (entry.item.clothingProductId === variant.productId) {
          let unitPrice: number | undefined;
          let totalPrice: number | undefined;
          let amount = entry.item.sizes?.reduce(
            (sum, size) => sum + (size.amountRequired || 0),
            0
          );
          if (
            entry.item.clothingProduct &&
            entry.item.clothingProduct.wholesaleThreshold &&
            entry.item.clothingProduct.wholesalePrice &&
            productTotalSum >= entry.item.clothingProduct.wholesaleThreshold &&
            entry.item.hasCustomPrice === undefined
          ) {
            unitPrice = entry.item.clothingProduct.wholesalePrice;

            totalPrice = amount
              ? entry.item.clothingProduct.wholesalePrice * amount
              : undefined;
          } else if (
            entry.item.clothingProduct?.price !== undefined &&
            entry.item.hasCustomPrice === undefined
          ) {
            unitPrice = entry.item.clothingProduct.price;
            totalPrice = amount
              ? entry.item.clothingProduct.price * amount
              : undefined;
          } else {
            unitPrice = entry.item.unitPrice;
            totalPrice = entry.item.totalPrice;
          }

          return {
            ...entry,
            item: {
              ...entry.item,
              totalCodeAmount: productTotalSum,
              unitPrice: unitPrice,
              totalPrice: totalPrice,
            },
          };
        } else {
          return entry;
        }
      });
      let calculatedTotalQuantity = calculateTotalQuantity(
        updatedNewSaleOrderItems
      );
      setTotalQuantity(calculatedTotalQuantity);
      setSaleOrderItems(updatedNewSaleOrderItems);
      generateTableHeaders(updatedNewSaleOrderItems);
      setEditingSaleOrder({
        ...editingSaleOrder,
        totalPrice: updatedNewSaleOrderItems.reduce(
          (total, formItem) => total + (formItem.item.totalPrice || 0),
          0
        ),
      });
    },
    [saleOrderItems, editingSaleOrder]
  );

  const fetchAvailableSizesForEntry = async (
    productId: number,
    colorId: number
  ) => {
    const productIdStr = productId.toString();
    const colorIdStr = colorId.toString();
    const limit = 200;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);
    additional.set("color_id", colorIdStr);

    try {
      const sizesStatus = await getList<Variant>(
        "/product_variants/with_related/",
        limit,
        offset,
        additional
      );

      if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
        const newSizes = sizesStatus.data.items.map((variant) => ({
          size: variant.sizeObject,
          sizeId: variant.sizeId,
          sizeName: variant.size,
          variant: variant,
          variantId: variant.id,
          amountRequired: undefined,
        }));
        return newSizes;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching sizes:", error);
      return [];
    }
  };

  useEffect(() => {
    setEditingSaleOrder(initialSaleOrder ? initialSaleOrder : newSaleOrder());
    const date = initialSaleOrder?.orderDate;
    setOrderDate(date ? moment(date) : "");
    setEditable(
      saleOrderIsEditable(initialSaleOrder ? initialSaleOrder : newSaleOrder())
    );
    getSaleOrderItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSaleOrder]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  const saleOrderItemsErrors = getFieldErrors(
    "saleOrderItems",
    errors
  ) as Errors[];
  return (
    <>
      <fieldset disabled={submitting || !editable}>
        <CFormGroup>
          <CRow className={"mt-2"}>
            <CCol md={2}>
              <CLabel className={"mt-2"}>Fecha:</CLabel>
            </CCol>
            <CCol>
              <Datetime
                className="pl-0"
                onChange={onOrderDatehange}
                value={orderDate}
                locale="es/PY"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                closeOnSelect={true}
              ></Datetime>
              <FieldErrors
                errors={getFieldErrors("orderDate", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CLabel>Cliente:</CLabel>
            </CCol>
            <CCol>
              <ClientSelect
                value={
                  editingSaleOrder.client !== undefined
                    ? editingSaleOrder.client
                    : null
                }
                onChange={onClientChange}
                disabled={!editable}
              ></ClientSelect>
              <FieldErrors
                errors={getFieldErrors("clientId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Monto Total:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  editingSaleOrder.totalPrice !== undefined
                    ? editingSaleOrder.totalPrice
                    : 0
                }
              ></CurrencyField>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingSaleOrder.obs)}
                onChange={onObsChange}
                placeholder="Observación"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2} lg={1} sm={2} className={"mt-2"}>
              <CLabel>Cantidad:</CLabel>
            </CCol>
            <CCol md={2} lg={2} xl={1}>
              <CInput
                type="number"
                value={amountTobeAdded}
                onChange={onAmountChange}
                min={1}
                placeholder="Cantidad"
              ></CInput>
            </CCol>
            <CCol md={2} lg={2} xl={1} className={"mt-2"}>
              <CLabel>Cód. de barras:</CLabel>
            </CCol>
            <CCol md={3} lg={2}>
              <CInput
                innerRef={barcodeInputRef}
                type="text"
                value={barcodeTobeAdded}
                onChange={onBarcodeChange}
                onKeyDown={onBarcodeKeyDown}
                placeholder="Código de barras"
              ></CInput>
            </CCol>
            <CCol md={1}>
              <CButton
                className="btn btn-primary"
                onClick={onBarcodeSend}
                disabled={submitting || !editable}
              >
                Ingresar
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={12}>
              <h5>Productos</h5>
              <h5 className="float-right">{`Total de artículos: ${totalQuantity}`}</h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-fixed">
                  <thead>
                    <tr>
                      {tableColumns.map((title, ix) => {
                        return (
                          <th
                            className="text-center"
                            key={ix}
                            style={{
                              verticalAlign: "middle",
                              overflow: "hidden",
                            }}
                          >
                            <div className="d-inline">{title}</div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {saleOrderItems.map((saleOrderFormItem, ix) => {
                      return (
                        <SaleOrderItemForm
                          disabled={!editable}
                          key={saleOrderFormItem.uuid}
                          value={saleOrderFormItem.item}
                          sizesColumns={tableColumns}
                          initialValue={saleOrderFormItem.item}
                          onDelete={() =>
                            onSaleOrderFormItemEntryDelete(saleOrderFormItem)
                          }
                          onChange={(item) =>
                            onSaleOrderFormItemEntryChange({
                              uuid: saleOrderFormItem.uuid,
                              item: item,
                            })
                          }
                          errors={
                            ix < saleOrderItemsErrors.length
                              ? saleOrderItemsErrors[ix]
                              : {}
                          }
                          allowUsingSuperWholesale={allowUsingSuperWholesale}
                        />
                      );
                    })}
                    {saleOrderItems.length === 0 ? (
                      <tr>
                        <td colSpan={tableColumns.length}>
                          Agregue nuevas entradas con el boton + de la derecha
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </CCol>
          </CRow>
          <CRow className="mb-1">
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddItemClick}
                disabled={!editable}
              >
                <i className="fa fa-plus" />
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            {/*<CButton type="button" color="secondary" onClick={onClose}>
              Atras
                  </CButton>*/}
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SaleOrderForm;
