import Color from "./color";
import Product from "./product";
import ProductOption from "./product-option";
import { ProductionOrderItem } from "./production-order";
import Size from "./size";

export interface ProductOptionFormItem {
  id?: number;
  clothingOptionId?: number;
  option?: ProductOption;
  value?: string;
}

export interface InputMaterialFormItem {
  id?: number;
  inputMaterialId?: number;
  inputMaterial?: number;
  amount?: number;
}

interface Variant {
  id?: number;
  companyId?: number;
  productId?: number;
  product?: Product;
  productName?: string;
  productCodeIdentifier?: string;
  name?: string;
  productType?: string;
  price?: number;
  wholesalePrice?: number;
  superWholesalePrice?: number;
  wholesaleThreshold?: number;
  superWholesaleThreshold?: number;
  colorId?: number;
  color?: string;
  sizeId?: number;
  size?: string;
  sizeObject?: Size;
  colorObject?: Color;
  inputMaterials?: InputMaterialFormItem[];
  options?: ProductOptionFormItem[];
  barcode?: string;
  barcode2?: string;
  taxType?: string;
  availableAmount?: number;
  categoryId?: number;
  productionCost?: number;
  priceWithDiscount?: number;
  priceWithDiscountWholesale?: number;
  batchId?: number;
  productionOrderId?: number;
  productionOrderItemId?: number;
}

export interface TableSizeEntry {
  savedId?: number;
  variantId?: number;
  amount?: number;
  variantName?: string;
  sizeName?: string;
  sizeId?: number;
  lostAmount?: number;
  maxAmountAllowed?: number;
  finishedAmount?: number;
  totalAmount?: number;
  barcodeBatch?: string;
  relatedOrderId?: number;
}

export interface VariantTableEntry {
  companyId?: number;
  clothingProductId?: number;
  colorName?: string;
  colorId?: number;
  productionCost?: number;
  productName?: string;
  productCodeIdentifier?: string;
  sizes?: TableSizeEntry[];
}

export function variantAPIToTable(variants: Variant[]): VariantTableEntry[] {
  const groupedItems: Map<string, Variant[]> = new Map();

  variants.forEach((item) => {
    const key = `${item.colorId}`;

    if (!groupedItems.has(key)) {
      groupedItems.set(key, []);
    }

    groupedItems.get(key)?.push(item);
  });
  const result: VariantTableEntry[] = Array.from(groupedItems.values()).map(
    (group) => {
      const firstItem = group[0];

      const nameParts = firstItem.name?.split("-");
      const extractedRowName = nameParts
        ? nameParts.slice(0, -1).join("-")
        : "-";

      const saleOrderFormItem: VariantTableEntry = {
        companyId: firstItem.companyId,
        clothingProductId: firstItem.productId,
        colorName: extractedRowName,
        colorId: firstItem.colorId,
        productionCost: firstItem.productionCost,
        productName: firstItem.productName,
        productCodeIdentifier: firstItem.productCodeIdentifier,
        sizes: group
          .map((item) => ({
            variantId: item.id,
            amount: 0,
            variantName: item.name,
            sizeName: item.size,
            sizeId: item.sizeId,
            barcodeBatch: item.barcode ? item.barcode : item.barcode2,
          }))
          .sort((a, b) => {
            const aSizeName = a.sizeName ? a.sizeName : "0";
            const bSizeName = b.sizeName ? b.sizeName : "0";

            const aIsNumeric = !isNaN(parseFloat(aSizeName));
            const bIsNumeric = !isNaN(parseFloat(bSizeName));

            if (aIsNumeric && bIsNumeric) {
              return parseFloat(aSizeName) - parseFloat(bSizeName);
            } else if (!aIsNumeric && !bIsNumeric) {
              return bSizeName.localeCompare(aSizeName);
            } else {
              return aIsNumeric ? -1 : 1;
            }
          }),
      };

      return saleOrderFormItem;
    }
  );

  return result;
}

export const variantTableFormatToAPIReceived = (
  items: VariantTableEntry[]
): VariantAmount[] => {
  const result: VariantAmount[] = [];

  items.forEach((item) => {
    item.sizes?.forEach((size) => {
      if (size.amount !== undefined && size.amount !== 0) {
        const newitem: VariantAmount = {
          variantId: size.variantId,
          amount: size.amount,
          variantName: size.variantName,
        };
        result.push(newitem);
      }
    });
  });

  return result;
};

export function productionOrderItemAPIToTable(
  orderitems: ProductionOrderItem[]
): VariantTableEntry[] {
  const groupedItems: Map<string, ProductionOrderItem[]> = new Map();

  orderitems.forEach((item) => {
    const key = `${item.colorName}`;

    if (!groupedItems.has(key)) {
      groupedItems.set(key, []);
    }

    groupedItems.get(key)?.push(item);
  });
  const result: VariantTableEntry[] = Array.from(groupedItems.values()).map(
    (group) => {
      const firstItem = group[0];

      const saleOrderFormItem: VariantTableEntry = {
        colorName: firstItem.colorName ? firstItem.colorName : "-",
        colorId: firstItem.colorId,
        productCodeIdentifier: firstItem.productCodeIdentifier,
        productName: firstItem.productName,
        sizes: group.map((item) => {
          const maxAmountAllowed =
            item.amount! - (item.finishedAmount || 0) - (item.lostAmount || 0);

          const compatibilityMax =
            maxAmountAllowed < 0
              ? item.amount! - (item.finishedAmount || 0)
              : maxAmountAllowed;
          return {
            savedId: item.id,
            variantId: item.variantId,
            amount: compatibilityMax,
            lostAmount: item.lostAmount,
            sizeName: item.sizeName,
            sizeId: item.sizeId,
            variantName: item.variant?.name,
            maxAmountAllowed: compatibilityMax,
            finishedAmount: item.finishedAmount,
            totalAmount: item.amount,
            barcodeBatch: item.barcodeBatch,
            relatedOrderId: item.orderId,
          };
        }),
      };

      return saleOrderFormItem;
    }
  );

  return result;
}

export const variantTableFormatToAPIProductionOrderItem = (
  items: VariantTableEntry[],
  isUpdate: boolean,
  operation_type: string | undefined
): ProductionOrderItem[] => {
  const result: ProductionOrderItem[] = [];

  items.forEach((item) => {
    item.sizes?.forEach((size) => {
      if (size.amount !== undefined && size.amount !== 0) {
        const finishedAmount =
          isUpdate === true
            ? operation_type === "success"
              ? size.finishedAmount
                ? size.finishedAmount + size.amount
                : size.amount
              : size.finishedAmount
            : 0;

        const amount = isUpdate === true ? size.totalAmount : size.amount;
        const lostAmount =
          isUpdate === true
            ? operation_type === "fail"
              ? size.lostAmount
                ? size.lostAmount + size.amount
                : size.amount
              : size.lostAmount
            : 0;
        const newitem: ProductionOrderItem = {
          id: size.savedId,
          variantId: size.variantId,
          colorId: item.colorId,
          colorName: item.colorName,
          sizeId: size.sizeId,
          sizeName: size.sizeName,
          amount: amount,
          lostAmount: lostAmount,
          finishedAmount: finishedAmount,
          barcodeBatch: size.barcodeBatch,
          productCodeIdentifier: item.productCodeIdentifier,
          productName: item.productName,
        };
        result.push(newitem);
      }
    });
  });

  return result;
};

export interface VariantAmount {
  variantId?: number;
  amount?: number;
  variantName?: string;
}

export interface VariantAmountObject {
  companyId?: number;
  clothingProductId?: number;
  inventoryLocationId?: number;
  totalValue?: number;
  amounts?: VariantAmount[];
  obs?: string;
}

export const newVariant = (): Variant => {
  return {};
};

export default Variant;
