import Category from "./category";
import Color from "./color";
import Size from "./size";

interface Product {
  id?: number;
  companyId?: number;
  name?: string;
  codeIdentifier?: string;
  description?: string;
  categoryName?: string;
  categoryId?: number;
  category?: Category;
  imageUrl?: string;
  price?: number;
  wholesalePrice?: number;
  superWholesalePrice?: number;
  averageFabricUsage?: number;
  fabricWidth?: number;
  wholesaleThreshold?: number;
  superWholesaleThreshold?: number;
  productType?: string;
  productionCost?: number;
  colors?: Color[];
  Sizes?: Size[];
  productionCostFabric?: number;
  productionCostCleaning?: number;
  productionCostCutting?: number;
  productionCostAccesories?: number;
  productionCostConfection?: number;
  productionCostWashing?: number;
  productionCostStoring?: number;
}

export interface ProductPriceLog {
  id?: number;
  companyId?: number;
  name?: string;
  categoryName?: string;
  price?: number;
  wholesalePrice?: number;
  superWholesalePrice?: number;
  productionCost?: number;
  createdAt?: string;
  updatedAt?: string;
}

export const PRODUCTTYPES: { [key: string]: string } = {
  shoes: "Calzados",
  pants: "Pantalones/Bermudas",
  shirts: "camisas/remeras",
  coats: "Abrigos",
  others: "Otros",
};

export const newProduct = (): Product => {
  return {};
};

export default Product;
