import { useCallback, useEffect, useState } from "react";
import Color from "../../models/color";
import Errors from "../../models/errors";
import Product from "../../models/product";
import {
  SaleOrderFormItem,
  SaleOrderFormItemSize,
} from "../../models/sale-order";
import ProductSelect from "../product/ProductSelect";
import SingleColorSelect from "../color/SingleColorSelect";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import { CButton, CButtonGroup, CInput, CTooltip } from "@coreui/react";
import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { emptyValueOnUndefined } from "../../utils/fields";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Variant from "../../models/variant";

interface SaleOrderItemFormProps {
  value?: SaleOrderFormItem;
  initialValue?: SaleOrderFormItem;
  initialErrors?: Errors;
  sizesColumns: string[];
  errors: Errors;
  disabled?: boolean;
  allowUsingSuperWholesale?: boolean;
  onChange: (saleOrderItem: SaleOrderFormItem) => void | Promise<void>;
  onDelete: (saleOrderItem: SaleOrderFormItem) => void | Promise<void>;
}

const FIXED_COLUMNS = [
  "Prenda",
  "Color",
  "Precio Unit.",
  "Subtotal",
  "Cant. Total",
  "Acciones",
];

const SaleOrderItemForm: React.FC<SaleOrderItemFormProps> = ({
  value,
  initialErrors,
  sizesColumns,
  initialValue,
  onChange,
  onDelete,
  errors,
  disabled,
  allowUsingSuperWholesale,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);

  const [editingItem, setEditingItem] = useState<SaleOrderFormItem>(
    value ? value : {}
  );
  const [product, setProduct] = useState<Product | undefined>(
    initialValue?.clothingProduct ? initialValue?.clothingProduct : undefined
  );
  const [color, setColor] = useState<Color | undefined>(
    initialValue?.color ? initialValue.color : undefined
  );
  const [sizeItems, setSizeItems] = useState<SaleOrderFormItemSize[]>(
    initialValue?.sizes ? initialValue.sizes : []
  );

  const [toggleSupperWholeSale, setToggleSupperWholeSaler] =
    useState<boolean>(false);

  const [totalRequiredClothesAmount, setTotalRequiredClothesAmount] =
    useState<number>(0);

  const onProductChange = useCallback(
    (newProduct: Product | null) => {
      const newItem = {
        ...editingItem,
        companyId: company.id,
        clothingProductId: newProduct?.id,
        clothingProduct: newProduct !== null ? newProduct : undefined,
        productCodeIdentifier: newProduct?.codeIdentifier,
        productName: newProduct?.name,
        unitPrice: newProduct?.price,
        colorName: undefined,
        color: undefined,
        colorId: undefined,
      };
      setEditingItem(newItem);
      setProduct(newProduct !== null ? newProduct : undefined);
      setColor(undefined);
      setSizeItems([]);
      setTotalRequiredClothesAmount(0);
      setToggleSupperWholeSaler(false);
      onChange(newItem);
    },
    [editingItem, company.id, onChange]
  );

  const onColorChange = useCallback(
    (newColor: Color | null) => {
      if (newColor !== null && newColor.id === color?.id && product) {
        fetchAvailableVariantsWithPreviousAmount(product, newColor, sizeItems);

        return;
      }
      const newItem = {
        ...editingItem,
        companyId: company.id,
        colorName: newColor !== null ? newColor.color : undefined,
        color: newColor !== null ? newColor : undefined,
        colorId: newColor !== null ? newColor.id : undefined,
      };
      setEditingItem(newItem);
      setColor(newColor !== null ? newColor : undefined);
      if (product === undefined) {
        setSizeItems([]);
        setTotalRequiredClothesAmount(0);
        onChange(newItem);
        return;
      }
      if (newColor === null) {
        setSizeItems([]);
        setTotalRequiredClothesAmount(0);
        onChange(newItem);
        return;
      }
      fetchAvailableVariants(product, newColor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem, company.id, onChange, product, sizeItems]
  );

  const onUnitPriceChange = useCallback(
    (newUnitPrice: number | undefined) => {
      let hasCustomPrice = undefined;

      if (
        newUnitPrice !== undefined &&
        editingItem.clothingProduct?.price !== undefined &&
        newUnitPrice !== editingItem.clothingProduct?.price &&
        editingItem.totalCodeAmount! <
          (editingItem.clothingProduct.wholesaleThreshold || 3)
      ) {
        hasCustomPrice = true;
      } else if (
        newUnitPrice !== undefined &&
        editingItem.clothingProduct?.wholesalePrice !== undefined &&
        newUnitPrice !== editingItem.clothingProduct?.wholesalePrice &&
        editingItem.totalCodeAmount! >=
          (editingItem.clothingProduct.wholesaleThreshold || 3)
      ) {
        hasCustomPrice = true;
      }
      const newItem = {
        ...editingItem,
        companyId: company.id,
        unitPrice: newUnitPrice,
        totalPrice: newUnitPrice
          ? newUnitPrice * totalRequiredClothesAmount
          : undefined,
        hasCustomPrice: hasCustomPrice,
      };
      setEditingItem(newItem);
      onChange(newItem);
    },
    [
      editingItem,
      company.id,
      onChange,
      totalRequiredClothesAmount,
      editingItem.totalCodeAmount,
    ]
  );

  const onSizeAmountChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      sizeItem: SaleOrderFormItemSize | undefined
    ) => {
      if (sizeItem === undefined) {
        onChange(editingItem);
        return;
      }
      const newAmountNumber = Number(e.target.value);
      const newSizeItems = [...sizeItems];
      const index = newSizeItems.findIndex((item) => {
        return item.sizeId === sizeItem.sizeId;
      });
      if (index === -1) {
        setSizeItems(newSizeItems);
      }

      const itemCopy = newSizeItems[index];
      const difference = newAmountNumber - (itemCopy.amountRequired || 0);

      itemCopy.amountRequired = newAmountNumber;
      newSizeItems[index] = itemCopy;
      setSizeItems(newSizeItems);

      const newTotalRequiredClothesAmount = newSizeItems.reduce(
        (total, item) => total + (item.amountRequired || 0),
        0
      );
      setTotalRequiredClothesAmount(newTotalRequiredClothesAmount);
      let currentPriceToMultiply = editingItem.unitPrice;

      if (currentPriceToMultiply === undefined) {
        onChange(editingItem);
        return;
      }

      const newEditingItem: SaleOrderFormItem = {
        ...editingItem,
        companyId: company.id,
        unitPrice: currentPriceToMultiply,
        totalCodeAmount: (editingItem.totalCodeAmount || 0) + difference,
        totalPrice: newTotalRequiredClothesAmount * currentPriceToMultiply,
        sizes: newSizeItems,
      };
      setEditingItem(newEditingItem);
      onChange(newEditingItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem, sizeItems, company.id, onChange]
  );

  const fetchAvailableVariants = useCallback(
    async (product: Product, color: Color) => {
      const productIdStr = product.id?.toString();
      const colorIdStr = color.id?.toString();
      const limit = 200;
      const offset = 0;
      const additional = new Map();
      additional.set("clothing_product_id", productIdStr);
      additional.set("color_id", colorIdStr);

      try {
        const sizesStatus = await getList<Variant>(
          "/product_variants/with_related/",
          limit,
          offset,
          additional
        );

        if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
          const newSizes = sizesStatus.data.items.map((variant) => ({
            size: variant.sizeObject,
            sizeId: variant.sizeId,
            sizeName: variant.size,
            variant: variant,
            variantId: variant.id,
            amountRequired: undefined,
          }));

          setSizeItems(newSizes);
          const newEditingItem = {
            ...editingItem,
            sizes: newSizes,
            colorName: color ? color.color : undefined,
            color: color,
            colorId: color.id,
            totalPrice: undefined,
          };
          setEditingItem(newEditingItem);
          onChange(newEditingItem);
        } else {
          setSizeItems([]);
        }
      } catch (error) {
        console.error("Error fetching sizes:", error);
        setSizeItems([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const fetchAvailableVariantsWithPreviousAmount = useCallback(
    async (
      product: Product,
      color: Color,
      receivedSizeItems: SaleOrderFormItemSize[]
    ) => {
      const productIdStr = product.id?.toString();
      const colorIdStr = color.id?.toString();
      const limit = 200;
      const offset = 0;
      const additional = new Map();
      additional.set("clothing_product_id", productIdStr);
      additional.set("color_id", colorIdStr);
      try {
        const sizesStatus = await getList<Variant>(
          "/product_variants/with_related/",
          limit,
          offset,
          additional
        );

        if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
          const newSizes = sizesStatus.data.items.map((variant) => {
            let newEntry: SaleOrderFormItemSize = {
              size: variant.sizeObject,
              sizeId: variant.sizeId,
              sizeName: variant.size,
              variant: variant,
              variantId: variant.id,
              amountRequired: undefined,
            };

            const matchSize = receivedSizeItems.find(
              (item) => item.sizeName === variant.size
            );

            if (matchSize) {
              newEntry = matchSize;
            }
            return newEntry;
          });

          setSizeItems(newSizes);
          const newEditingItem = {
            ...editingItem,
            sizes: newSizes,
          };
          setEditingItem(newEditingItem);
          onChange(newEditingItem);
        } else {
          setSizeItems([]);
        }
      } catch (error) {
        console.error("Error fetching sizes:", error);
        setSizeItems([]);
      }
    },
    [editingItem, onChange]
  );

  const onToggleWholesaleClick = useCallback(() => {
    let newPrice = editingItem.unitPrice;
    let hasCustomPrice = editingItem.hasCustomPrice;
    const newToggleStatus = !toggleSupperWholeSale;
    if (editingItem.clothingProduct?.superWholesalePrice) {
      if (newToggleStatus) {
        newPrice = editingItem.clothingProduct?.superWholesalePrice;
        hasCustomPrice = true;
      } else {
        if (
          editingItem.clothingProduct.wholesaleThreshold &&
          totalRequiredClothesAmount &&
          totalRequiredClothesAmount >=
            editingItem.clothingProduct.wholesaleThreshold
        ) {
          newPrice = editingItem.clothingProduct?.wholesalePrice;
          hasCustomPrice = undefined;
        } else {
          newPrice = editingItem.clothingProduct?.price;
          hasCustomPrice = undefined;
        }
      }
      const newItem = {
        ...editingItem,
        companyId: company.id,
        unitPrice: newPrice,
        hasCustomPrice: hasCustomPrice,
        totalPrice: newPrice
          ? newPrice * totalRequiredClothesAmount
          : undefined,
      };
      setEditingItem(newItem);

      onChange(newItem);
    } else {
      onChange(editingItem);
    }

    setToggleSupperWholeSaler(newToggleStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    toggleSupperWholeSale,
    editingItem,
    onChange,
    totalRequiredClothesAmount,
  ]);

  useEffect(() => {
    if (initialValue?.clothingProduct === undefined) {
      return;
    }
    if (initialValue?.color === undefined) {
      return;
    } else {
      setColor(editingItem.color);
    }
    const initialSizes = initialValue.sizes ? initialValue.sizes : [];
    const newTotalRequiredClothesAmount = initialSizes.reduce(
      (total, item) => total + (item.amountRequired || 0),
      0
    );
    setTotalRequiredClothesAmount(newTotalRequiredClothesAmount);
    if (initialSizes.length > 0) {
      setSizeItems(initialSizes);
      return;
    }
    addAvailableSizes(
      initialValue?.clothingProduct,
      initialValue?.color,
      initialSizes
    );
    if (
      initialValue.hasCustomPrice &&
      initialValue.unitPrice ===
        initialValue.clothingProduct.superWholesalePrice
    ) {
      setToggleSupperWholeSaler(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (
      initialValue?.totalCodeAmount === undefined ||
      editingItem.clothingProduct?.wholesalePrice === undefined ||
      editingItem.clothingProduct?.wholesaleThreshold === undefined ||
      toggleSupperWholeSale ||
      editingItem.hasCustomPrice === true
    ) {
      return;
    }
    const newEditingItem = { ...editingItem };
    const newTotalRequiredClothesAmount = initialValue.sizes
      ? initialValue.sizes.reduce(
          (total, item) => total + (item.amountRequired || 0),
          0
        )
      : 0;
    setTotalRequiredClothesAmount(newTotalRequiredClothesAmount);
    if (
      initialValue?.totalCodeAmount >=
      editingItem.clothingProduct.wholesaleThreshold
    ) {
      newEditingItem.unitPrice = editingItem.clothingProduct.wholesalePrice;
      newEditingItem.totalPrice =
        editingItem.clothingProduct.wholesalePrice *
        newTotalRequiredClothesAmount;
    } else if (editingItem.clothingProduct.price !== undefined) {
      newEditingItem.unitPrice = editingItem.clothingProduct.price;
      newEditingItem.totalPrice =
        editingItem.clothingProduct.price * newTotalRequiredClothesAmount;
    }

    setEditingItem(newEditingItem);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, initialValue?.totalCodeAmount]);

  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem, onDelete]);

  const addAvailableSizes = async (
    product: Product,
    color: Color,
    initialSizes: SaleOrderFormItemSize[]
  ) => {
    const productIdStr = product.id?.toString();
    const colorIdStr = color.id?.toString();
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);
    additional.set("color_id", colorIdStr);

    try {
      const sizesStatus = await getList<Variant>(
        "/product_variants/with_related/",
        limit,
        offset,
        additional
      );

      if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
        const newSizes: SaleOrderFormItemSize[] = sizesStatus.data.items.map(
          (variant) => {
            const existingSize = initialSizes.find(
              (size) => size.sizeId === variant.sizeId
            );

            if (existingSize) {
              return existingSize;
            } else {
              return {
                size: variant.sizeObject,
                sizeId: variant.sizeId,
                sizeName: variant.size,
                variant: variant,
                variantId: variant.id,
                amountRequired: undefined,
              };
            }
          }
        );

        setSizeItems(newSizes);
        const newTotalRequiredClothesAmount = newSizes.reduce(
          (total, item) => total + (item.amountRequired || 0),
          0
        );
        setTotalRequiredClothesAmount(newTotalRequiredClothesAmount);
        const newEditingItem = {
          ...editingItem,
          sizes: newSizes,
        };
        setEditingItem(newEditingItem);
        onChange(newEditingItem);
      } else {
        setSizeItems([]);
      }
    } catch (error) {
      console.error("Error fetching sizes:", error);
      setSizeItems([]);
    }
  };

  return (
    <tr style={{ verticalAlign: "middle", overflow: "hidden" }}>
      <td style={{ padding: 0, minWidth: "200px" }}>
        <ProductSelect
          value={product ? product : null}
          onChange={onProductChange}
          disabled={disabled}
        ></ProductSelect>
      </td>
      <td style={{ padding: 0, width: "120px" }}>
        <SingleColorSelect
          value={color ? color : null}
          onChange={onColorChange}
          productId={product?.id}
          key={product ? product.id : 0}
          disabled={disabled}
        ></SingleColorSelect>
      </td>
      <td style={{ padding: 0, width: "100px" }}>
        <CurrencyField
          currency={PYG}
          value={editingItem ? editingItem.unitPrice : undefined}
          onChange={onUnitPriceChange}
          disabled={!allowUsingSuperWholesale}
        ></CurrencyField>
      </td>
      <td style={{ padding: 0, width: "100px" }}>
        <CurrencyField
          disabled
          currency={PYG}
          value={editingItem ? editingItem.totalPrice : undefined}
        ></CurrencyField>
      </td>
      <td style={{ padding: 0, width: "70px" }}>
        <CInput disabled value={totalRequiredClothesAmount}></CInput>
      </td>
      {sizesColumns.map((column, ix) => {
        if (FIXED_COLUMNS.includes(column)) {
          return null;
        }
        const matchSize = sizeItems.find((item) => item.sizeName === column);

        return (
          <td key={ix} style={{ padding: 0, width: "70px" }}>
            <CInput
              type="number"
              min={0}
              value={
                matchSize ? emptyValueOnUndefined(matchSize.amountRequired) : ""
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onSizeAmountChange(e, matchSize)
              }
              disabled={matchSize === undefined}
            ></CInput>
          </td>
        );
      })}
      <td className="text-center" style={{ padding: 0, width: "60px" }}>
        <CButtonGroup>
          {allowUsingSuperWholesale ? (
            <CTooltip content="Alternar SuperMayorista">
              <CButton
                className="text-white"
                color="success"
                onClick={() => {
                  onToggleWholesaleClick();
                }}
                disabled={disabled}
              >
                <i
                  className={`fa fa-arrow-${
                    toggleSupperWholeSale ? "down-short-wide" : "up-wide-short"
                  }`}
                ></i>
              </CButton>
            </CTooltip>
          ) : (
            <></>
          )}
          <CTooltip content="Borrar">
            <CButton
              className="text-white"
              color="danger"
              onClick={() => {
                onDeleteClick();
              }}
              disabled={disabled}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          </CTooltip>
        </CButtonGroup>
      </td>
    </tr>
  );
};

export default SaleOrderItemForm;
