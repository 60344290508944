import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CTooltip,
  CBadge,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getFile, getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import { formatDateTime } from "../../utils/dates";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import StoreSale, { getStoreSaleOperationType } from "../../models/store-sale";
import { getPaymentTypeLabel } from "../../models/client";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { defaultValueOnUndefined } from "../../utils/fields";
import SaleDetailModal from "../sales-history/SaleDetailModal";
import SingleStoreSelect from "../store/SingleStoreSelect";
import Store from "../../models/store";
import "../inventory-products/custom-style.css";
import StoreExpense, {
  getStoreExpenseStatusClass,
  getStoreExpenseStatusLabel,
  getStoreExpenseTypeLabel,
} from "../../models/store-expense";
import generateIncomeExpenseReportPDF from "../../utils/IncomeExpensePdfGenerator";
import { Spinner } from "react-bootstrap";
import { getAbsoluteUrl } from "../../api/urls";

const ITEMS_PER_PAGE = 50;
const ITEMS_PER_PAGE_EXPENSES = 10;

const IncomeExpenseSummaryList = () => {
  const fields = [
    {
      key: "id",
      _classes: ["text-center"],
      label: "Nro de Venta",
    },
    {
      key: "documentNumber",
      _classes: ["text-center"],
      label: "Nro de factura",
    },
    {
      key: "orderDate",
      _classes: ["text-center"],
      label: "Fecha",
    },
    {
      key: "storeName",
      _classes: ["text-center"],
      label: "Sucursal",
    },
    {
      key: "sellerName",
      _classes: ["text-center"],
      label: "Usuario",
    },
    {
      key: "clientName",
      _classes: ["text-center"],
      label: "Cliente",
    },
    {
      key: "clientRuc",
      _classes: ["text-center"],
      label: "RUC",
    },
    {
      key: "isReturnOperation",
      _classes: ["text-center"],
      label: "Tipo",
    },
    {
      key: "cancelled",
      _classes: ["text-center"],
      label: "Anulado",
    },
    {
      key: "paymentType",
      _classes: ["text-center"],
      label: "Forma de pago",
    },
    {
      key: "noTaxed",
      _classes: ["text-center"],
      label: "Exentas",
    },
    {
      key: "taxed",
      _classes: ["text-center"],
      label: "Gravadas",
    },
    {
      key: "discountedPrice",
      _classes: ["text-center"],
      label: "Venta total",
    },
    {
      key: "iva",
      _classes: ["text-center"],
      label: "IVA",
    },
    {
      key: "saleProfit",
      _classes: ["text-center"],
      label: "Ganancia",
    },
    {
      key: "actions",
      _classes: ["text-center index-custom"],
      label: "Acciones",
      filter: false,
    },
  ];

  const fieldsExpenses = [
    {
      key: "expenseDate",
      _classes: ["text-center"],
      label: "Fecha",
    },
    {
      key: "storeName",
      _classes: ["text-center"],
      label: "Sucursal",
    },
    {
      key: "userName",
      _classes: ["text-center"],
      label: "Usuario",
    },
    {
      key: "expenseType",
      _classes: ["text-center"],
      label: "Tipo",
    },
    {
      key: "description",
      _classes: ["text-center"],
      label: "Descripción",
    },
    {
      key: "price",
      _classes: ["text-center"],
      label: "Monto",
    },
    {
      key: "status",
      _classes: ["text-center"],
      label: "Estado",
    },
    {
      key: "revertDescription",
      _classes: ["text-center"],
      label: "Motivo Reversión",
    },
    {
      key: "revertDate",
      _classes: ["text-center"],
      label: "Fecha Reversión",
    },
    {
      key: "revertUserName",
      _classes: ["text-center"],
      label: "Usuario Reversión",
    },
  ];

  const [loading, setLoading] = useState(true);
  const [filterStore, setFilterStore] = useState<Store | undefined>(undefined);
  const [selectedStore, setSelectedStore] = useState<Store | undefined>(
    undefined
  );
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [storeSale, setStoreSale] = useState<StoreSale | undefined>(undefined);
  const [storeSales, setStoreSales] = useState<StoreSale[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotalPrice, setPageTotalPrice] = useState<number | undefined>(0);
  const [pageTotalProfit, setPageTotalProfit] = useState<number | undefined>(0);
  const [pageTotalCost, setPageTotalCost] = useState<number | undefined>(0);
  const [queryTotalPrice, setQueryTotalPrice] = useState<number | undefined>(
    undefined
  );
  const [queryTotalProfit, setQueryTotalProfit] = useState<number | undefined>(
    undefined
  );
  const [queryTotalCost, setQueryTotalCost] = useState<number | undefined>(
    undefined
  );
  const [queryCashOnlyTotalPrice, setQueryCashOnlyTotalPrice] = useState<
    number | undefined
  >(undefined);
  const [totalPagesExpenses, setTotalPagesExpenses] = useState(1);
  const [currentPageExpenses, setCurrentPageExpenses] = useState(1);
  const history = useHistory();
  const [pageTotalPriceExpense, setPageTotalPriceExpense] = useState<
    number | undefined
  >(0);
  const [queryTotalPriceExpense, setQueryTotalPriceExpense] = useState<
    number | undefined
  >(undefined);
  const [queryTotalInStoreExpense, setQueryTotalInStoreExpense] = useState<
    number | undefined
  >(undefined);
  const [queryCashTotalInStoreExpense, setQueryCashTotalInStoreExpense] =
    useState<number | undefined>(undefined);
  const [queryTotalInFactoryExpense, setQueryTotalInFactoryExpense] = useState<
    number | undefined
  >(undefined);
  const [storeExpenses, setStoreExpenses] = useState<StoreExpense[]>([]);

  const [enableReportButton, setEnableReportButton] = useState(true);
  const [generatingReport, setGeneratingReport] = useState(false);

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let storeId = urlParams.get("store_id") ? urlParams.get("store_id") : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";

    let pageExpense = urlParams.get("page_expense")
      ? parseInt(urlParams.get("page_expense") as string)
      : 1;
    if (pageExpense <= 0) {
      pageExpense = 1;
    }
    let map = new Map();
    map.set("page", page);
    map.set("page_expense", pageExpense);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    if (storeId !== "") map.set("store_id", storeId);
    return map;
  };

  const getURLParamsSales = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let storeId = urlParams.get("store_id") ? urlParams.get("store_id") : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("page", page);

    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    if (storeId !== "") map.set("store_id", storeId);
    return map;
  };

  const fetchStoreSales = async () => {
    const urlParams = getURLParamsSales();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const storeSalesStatus = await getList<StoreSale>(
      "/store_sales/",
      limit,
      offset,
      urlParams
    );
    if (storeSalesStatus.status === SUCCESS) {
      if (storeSalesStatus.data !== undefined) {
        const count = storeSalesStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setPageTotalPrice(calculatePageTotal(storeSalesStatus.data.items));
        setPageTotalProfit(
          calculatePageTotalProfit(storeSalesStatus.data.items)
        );
        setPageTotalCost(calculatePageTotalCost(storeSalesStatus.data.items));
        setStoreSales(storeSalesStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = storeSalesStatus.detail
        ? storeSalesStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchTotals = async () => {
    const urlParams = getURLParamsSales();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    urlParams.set("limit", limit);
    urlParams.set("offset", offset);
    const totalsStatus = await getItem<{
      fullCost?: number;
      fullTotal?: number;
      fullProfit?: number;
      cashOnlyTotal?: number;
    }>("/store_sales/totals/", urlParams);
    if (totalsStatus.status === SUCCESS) {
      if (totalsStatus.data !== undefined) {
        setQueryTotalPrice(totalsStatus.data.fullTotal);
        setQueryTotalProfit(totalsStatus.data.fullProfit);
        setQueryTotalCost(totalsStatus.data.fullCost);
        setQueryCashOnlyTotalPrice(totalsStatus.data.cashOnlyTotal);
      }
    } else {
      const message = totalsStatus.detail
        ? totalsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const getURLParamsExpenses = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page_expense")
      ? parseInt(urlParams.get("page_expense") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let storeId = urlParams.get("store_id") ? urlParams.get("store_id") : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("page", page);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    if (storeId !== "") map.set("store_id", storeId);
    return map;
  };

  const fetchStoreExpenses = async () => {
    const urlParams = getURLParamsExpenses();
    const limit = ITEMS_PER_PAGE_EXPENSES;
    const offset = ITEMS_PER_PAGE_EXPENSES * Number(urlParams.get("page") - 1);
    const requestStatus = await getList<StoreExpense>(
      "/store_expenses/",
      limit,
      offset,
      urlParams
    );
    if (requestStatus.status === SUCCESS) {
      if (requestStatus.data !== undefined) {
        const count = requestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE_EXPENSES);
        setTotalPagesExpenses(pages);
        setPageTotalPriceExpense(
          calculatePageTotalExpense(requestStatus.data.items)
        );
        setStoreExpenses(requestStatus.data.items);
        setCurrentPageExpenses(urlParams.get("page"));
      }
    } else {
      const message = requestStatus.detail
        ? requestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchTotalsExpenses = async () => {
    const urlParams = getURLParamsExpenses();
    const limit = ITEMS_PER_PAGE_EXPENSES;
    const offset = ITEMS_PER_PAGE_EXPENSES * Number(urlParams.get("page") - 1);
    urlParams.set("limit", limit);
    urlParams.set("offset", offset);
    const totalsStatus = await getItem<{
      fullTotal?: number;
      inFactoryTotal?: number;
      inStoreTotal?: number;
      inStoreCashTotal?: number;
    }>("/store_expenses/totals/", urlParams);
    if (totalsStatus.status === SUCCESS) {
      if (totalsStatus.data !== undefined) {
        setQueryTotalPriceExpense(totalsStatus.data.fullTotal);
        setQueryTotalInStoreExpense(totalsStatus.data.inStoreTotal);
        setQueryCashTotalInStoreExpense(totalsStatus.data.inStoreCashTotal);
        setQueryTotalInFactoryExpense(totalsStatus.data.inFactoryTotal);
      }
    } else {
      const message = totalsStatus.detail
        ? totalsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const calculatePageTotal = (sales: StoreSale[]): number => {
    const total = sales
      .filter((sale) => !sale.cancelled)
      .reduce((acc, sale) => {
        const price = sale.discountedPrice ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePageTotalProfit = (sales: StoreSale[]): number => {
    const total = sales
      .filter((sale) => !sale.cancelled)
      .reduce((acc, sale) => {
        const price = sale.saleProfit ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePageTotalCost = (sales: StoreSale[]): number => {
    const total = sales
      .filter((sale) => !sale.cancelled)
      .reduce((acc, sale) => {
        const price = sale.saleProductCosts ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePageTotalExpense = (sales: StoreExpense[]): number => {
    const total = sales
      .filter((sale) => !sale.reverted)
      .reduce((acc, sale) => {
        const price = sale.price ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    if (previousParams.get("store_id")) {
      urlParams.set("store_id", previousParams.get("store_id"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    if (previousParams.get("page_expense")) {
      urlParams.set("page_expense", previousParams.get("page_expense"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onPageChangeExpense = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    if (previousParams.get("store_id")) {
      urlParams.set("store_id", previousParams.get("store_id"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    if (previousParams.get("page")) {
      urlParams.set("page", previousParams.get("page"));
    }
    urlParams.set("page_expense", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onFilterStoreChange = (newStore: Store | null) => {
    setFilterStore(newStore !== null ? newStore : undefined);
  };

  const onDetailClick = (storeSale: StoreSale) => {
    setStoreSale(storeSale);
    setShowDetailModal(true);
  };

  const onDetailModalCancel = () => {
    setStoreSale(undefined);
    setShowDetailModal(false);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (filterStore !== undefined && filterStore.id !== undefined) {
      urlParams.set("store_id", filterStore.id.toString());
    } else {
      urlParams.delete("store_id");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const fetchSelectedStore = async () => {
    const urlParams = getURLParamsSales();
    const id = urlParams.get("store_id");
    if (id === undefined) {
      setSelectedStore(undefined);
      return;
    }
    const storeStatus = await getItem<Store>(`/stores/${id}/`);
    if (storeStatus.status === SUCCESS) {
      if (storeStatus.data !== undefined) {
        setSelectedStore(storeStatus.data);
      }
    } else {
      const message = storeStatus.detail
        ? storeStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const evaluateReport = async () => {
    setGeneratingReport(false);
    const urlParams = getURLParams();

    const startDateStr = urlParams.get("start_date");
    const endDateStr = urlParams.get("end_date") || new Date().toISOString();

    if (!startDateStr) {
      setEnableReportButton(false);
      return;
    }

    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      setEnableReportButton(false);
      return;
    }

    const diffInDays = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    if (diffInDays > 31) {
      setEnableReportButton(false);
      return;
    }
    setEnableReportButton(true);
  };

  const onReportClick = async () => {
    setEnableReportButton(false);
    setGeneratingReport(true);
    const urlParams = getURLParams();

    const startDateStr = urlParams.get("start_date");
    const endDateStr = urlParams.get("end_date") || new Date().toISOString();

    if (!startDateStr) {
      warningAlert(
        "El rango de fechas para reporte no debe ser mayor a un mes (31 días)."
      );
      setEnableReportButton(true);
      setGeneratingReport(false);
      return;
    }

    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Verificar que las fechas sean válidas
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      warningAlert("Los parámetros de fechas son inválidos.");
      setEnableReportButton(true);
      setGeneratingReport(false);
      return;
    }

    // Verificar rango de fechas (máximo 31 días)
    const diffInDays = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    if (diffInDays > 31) {
      warningAlert(
        "El rango de fechas para reporte no debe ser mayor a un mes (31 días)."
      );
      setEnableReportButton(true);
      setGeneratingReport(false);
      return;
    }

    const titlesRight: string[] = generateTitlesRight(urlParams);

    const filteredTitlesRight = titlesRight.filter((title) => title !== "");
    await generateIncomeExpenseReportPDF(
      [
        "Reporte de Ganancias",
        "",
        "Reporte generado el:",
        formatDateTime(new Date()),
      ],
      filteredTitlesRight,
      selectedStore,
      queryTotalPrice || 0,
      queryCashOnlyTotalPrice || 0,
      queryTotalCost || 0,
      queryTotalProfit || 0,
      queryTotalInStoreExpense || 0,
      queryCashTotalInStoreExpense || 0,
      queryTotalInFactoryExpense || 0,
      queryTotalPriceExpense || 0,
      (queryCashOnlyTotalPrice || 0) - (queryCashTotalInStoreExpense || 0),
      (queryTotalProfit || 0) - (queryTotalPriceExpense || 0)
    );
    setEnableReportButton(true);
    setGeneratingReport(false);
  };

  const generateTitlesRight = (map: Map<any, any>): string[] => {
    const titlesRight: string[] = ["Filtros Utilizados: "];

    map.forEach((value, key) => {
      const stringKey = typeof key === "string" ? key : "";
      const stringValue = typeof value === "string" ? value : "";
      const description = getFilterDescription(stringKey, stringValue);
      titlesRight.push(description);
    });

    return titlesRight;
  };

  const getFilterDescription = (key: string, value: string) => {
    switch (key) {
      case "search":
        if (value !== "") {
          return `Búsqueda: ${value}`;
        } else {
          return "";
        }
      case "is_returnal":
        if (value === "true") {
          return "Tipo de Operación: Devolución";
        } else if (value === "false") {
          return "Tipo de Operación: Devolución";
        } else {
          return "";
        }
      case "cash_register_operation_id":
        return `Corresponde a ciclo de caja: Si`;
      case "start_date":
        return `Fecha de inicio: ${formatDateTime(new Date(value))}`;
      case "end_date":
        return `Fecha de fin: ${formatDateTime(new Date(value))}`;
      default:
        return "";
    }
  };

  const onDailyReportClick = async () => {
    setEnableReportButton(false);
    setGeneratingReport(true);
    const urlParams = getURLParams();

    const startDateStr = urlParams.get("start_date");
    const endDateStr = urlParams.get("end_date") || new Date().toISOString();

    if (!startDateStr) {
      warningAlert(
        "Debe proporcionar un rango de fechas para generar el reporte."
      );
      setEnableReportButton(true);
      setGeneratingReport(false);
      return;
    }

    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Validate dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      warningAlert("Los parámetros de fechas son inválidos.");
      setEnableReportButton(true);
      setGeneratingReport(false);
      return;
    }

    // Validate date range (max 31 days)
    const diffInDays = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    if (diffInDays > 31) {
      warningAlert(
        "El rango de fechas para el reporte no debe ser mayor a un mes (31 días)."
      );
      setEnableReportButton(true);
      setGeneratingReport(false);
      return;
    }

    const fileResponse = await getFile("/profit_report/", urlParams);

    if (fileResponse.status === SUCCESS && fileResponse.data) {
      const url = window.URL.createObjectURL(fileResponse.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "daily_report.pdf"; // Puedes ajustar el nombre del archivo
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      console.error(
        `Error al descargar el reporte: ${fileResponse.detail || "Desconocido"}`
      );
      alert("No se pudo descargar el reporte. Inténtalo de nuevo más tarde.");
    }

    setEnableReportButton(true);
    setGeneratingReport(false);
  };

  const getInitialValues = async () => {
    setLoading(true);
    const evaluatePromise = evaluateReport();
    const salesPromise = fetchStoreSales();
    const salesTotalsPromise = fetchTotals();
    const expensesPromise = fetchStoreExpenses();
    const expensesTotalsPromise = fetchTotalsExpenses();
    const selectedStorePromise = fetchSelectedStore();
    await evaluatePromise;
    await salesPromise;
    await salesTotalsPromise;
    await expensesPromise;
    await expensesTotalsPromise;
    await selectedStorePromise;
    setLoading(false);
  };

  useEffect(() => {
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("incomeExpenseSummary")) {
        return;
      }
      getInitialValues();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Detalle de Gastos y Ganancias</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <SingleStoreSelect
                    value={filterStore ? filterStore : null}
                    onChange={onFilterStoreChange}
                  ></SingleStoreSelect>
                </CCol>
              </CRow>

              <CRow className={"mt-2 mb-4"}>
                <CCol md={3}>
                  <CButton
                    type="button"
                    color="primary"
                    variant="outline"
                    onClick={onSearch}
                  >
                    Filtrar
                  </CButton>
                </CCol>
              </CRow>

              <CTabs activeTab="sales">
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink data-tab="sales">VENTAS EN SUCURSAL</CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink data-tab="expenses">GASTOS EN SUCURSAL</CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink data-tab="summary">RESUMEN DE GANANCIA</CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent>
                  <CTabPane data-tab="sales">
                    <div>
                      <div>
                        <CDataTable
                          noItemsView={
                            <h2 className="text-center">Sin Resultados</h2>
                          }
                          addTableClasses={"table-fixed"}
                          fields={fields}
                          items={storeSales}
                          striped
                          border
                          loading={loading}
                          responsive
                          scopedSlots={{
                            id: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  <span className="">
                                    {item.id ? `#${item.id}` : "-"}
                                  </span>
                                </td>
                              );
                            },
                            documentNumber: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  {item.documentNumber
                                    ? `${item.establishment}-${item.dispatchPoint}-${item.documentNumber}`
                                    : "-"}
                                </td>
                              );
                            },

                            cancelled: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  {item.cancelled ? (
                                    <CBadge color={"danger"}>
                                      Operación Anulada
                                    </CBadge>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              );
                            },

                            orderDate: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  {item.saleDate
                                    ? formatDateTime(new Date(item.saleDate))
                                    : "-"}
                                </td>
                              );
                            },
                            clientName: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  {defaultValueOnUndefined(
                                    "SIN NOMBRE",
                                    item.clientName
                                  )}
                                </td>
                              );
                            },
                            storeName: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  {defaultValueOnUndefined("-", item.storeName)}
                                </td>
                              );
                            },
                            clientRuc: (item: StoreSale) => {
                              let valueToShow: string;
                              if (item.clientRuc !== undefined) {
                                valueToShow = item.clientRuc;
                              } else if (item.clientDocument !== undefined) {
                                valueToShow = item.clientDocument;
                              } else {
                                valueToShow = "-";
                              }
                              return (
                                <td className="text-center">{valueToShow}</td>
                              );
                            },
                            paymentType: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  {item.paymentType
                                    ? getPaymentTypeLabel(item)
                                    : "-"}
                                </td>
                              );
                            },
                            isReturnOperation: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  {getStoreSaleOperationType(item)}
                                </td>
                              );
                            },
                            noTaxed: (item: StoreSale) => {
                              const show = item.isReturnOperation
                                ? !item.isReturnOperation
                                : true;

                              return (
                                <td className="text-center">
                                  {show
                                    ? item.noTaxed
                                      ? formatToCurrency(item.noTaxed, PYG)
                                      : "-"
                                    : "-"}
                                </td>
                              );
                            },

                            taxed: (item: StoreSale) => {
                              const show = item.isReturnOperation
                                ? !item.isReturnOperation
                                : true;
                              return (
                                <td className="text-center">
                                  {show
                                    ? item.taxed
                                      ? formatToCurrency(item.taxed, PYG)
                                      : "-"
                                    : "-"}
                                </td>
                              );
                            },
                            discountedPrice: (item: StoreSale) => {
                              return (
                                <td className="text-center">
                                  {item.discountedPrice
                                    ? formatToCurrency(
                                        item.discountedPrice,
                                        PYG
                                      )
                                    : "-"}
                                </td>
                              );
                            },

                            iva: (item: StoreSale) => {
                              const show = item.isReturnOperation
                                ? !item.isReturnOperation
                                : true;
                              return (
                                <td className="text-center">
                                  {show
                                    ? item.iva
                                      ? formatToCurrency(item.iva, PYG)
                                      : "-"
                                    : "-"}
                                </td>
                              );
                            },

                            saleProfit: (item: StoreSale) => {
                              const show = item.isReturnOperation
                                ? !item.isReturnOperation
                                : true;
                              return (
                                <td className="text-center">
                                  {show
                                    ? item.saleProfit
                                      ? formatToCurrency(item.saleProfit, PYG)
                                      : "-"
                                    : "-"}
                                </td>
                              );
                            },

                            actions: (item: StoreSale) => {
                              const detailButton = (
                                <CTooltip content={"Ver Detalles"}>
                                  <CButton
                                    className="text-white"
                                    color="primary"
                                    onClick={() => {
                                      onDetailClick(item);
                                    }}
                                  >
                                    <i className="fa fa-eye"></i>
                                  </CButton>
                                </CTooltip>
                              );

                              return (
                                <td className="text-right">
                                  <CButtonGroup>{detailButton}</CButtonGroup>
                                </td>
                              );
                            },
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        <CPagination
                          pages={totalPages}
                          activePage={currentPage}
                          onActivePageChange={(i: number) => onPageChange(i)}
                          className={totalPages < 2 ? "d-none" : "mt-2"}
                        />
                      </div>
                    </div>
                    <CRow className={"mt-2"}>
                      <CCol className={"border border-dark"}>
                        <CRow className={"mt-2"}>
                          <CCol>
                            <h5>Valores Totales de selección:</h5>
                          </CCol>
                        </CRow>
                        <CRow className={"mt-2"}>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              Venta total de la pagina visualizada:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {pageTotalPrice
                                ? formatToCurrency(pageTotalPrice, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              Venta total todas las páginas:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalPrice
                                ? formatToCurrency(queryTotalPrice, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow className={"mt-2"}>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              Costo total de productos vendidos de la pagina
                              visualizada:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {pageTotalCost
                                ? formatToCurrency(pageTotalCost, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              Costo total todas las páginas:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalCost
                                ? formatToCurrency(queryTotalCost, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow className={"mt-2"}>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              Ganancia total de la pagina visualizada:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {pageTotalProfit
                                ? formatToCurrency(pageTotalProfit, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              Ganancia total todas las páginas:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalProfit
                                ? formatToCurrency(queryTotalProfit, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CTabPane>
                  <CTabPane data-tab="expenses">
                    <div>
                      <CDataTable
                        noItemsView={
                          <h2 className="text-center">Sin Resultados</h2>
                        }
                        addTableClasses={"table-fixed"}
                        fields={fieldsExpenses}
                        items={storeExpenses}
                        striped
                        border
                        loading={loading}
                        responsive
                        scopedSlots={{
                          expenseDate: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                {item.expenseDate
                                  ? formatDateTime(new Date(item.expenseDate))
                                  : "-"}
                              </td>
                            );
                          },
                          revertDate: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                {item.revertDate
                                  ? formatDateTime(new Date(item.revertDate))
                                  : "-"}
                              </td>
                            );
                          },
                          storeName: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                {defaultValueOnUndefined("-", item.storeName)}
                              </td>
                            );
                          },
                          revertDescription: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                {defaultValueOnUndefined(
                                  "-",
                                  item.revertDescription
                                )}
                              </td>
                            );
                          },
                          price: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                {formatToCurrency(item.price || 0, PYG)}
                              </td>
                            );
                          },
                          userName: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                {defaultValueOnUndefined("-", item.userName)}
                              </td>
                            );
                          },
                          revertUserName: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                {defaultValueOnUndefined(
                                  "-",
                                  item.revertUserName
                                )}
                              </td>
                            );
                          },
                          expenseType: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                {item.expenseType
                                  ? getStoreExpenseTypeLabel(item.expenseType)
                                  : "-"}
                              </td>
                            );
                          },
                          status: (item: StoreExpense) => {
                            return (
                              <td className="text-center">
                                <CBadge
                                  color={
                                    item.status
                                      ? getStoreExpenseStatusClass(item.status)
                                      : undefined
                                  }
                                >
                                  {item.status
                                    ? getStoreExpenseStatusLabel(item.status)
                                    : undefined}
                                </CBadge>
                              </td>
                            );
                          },
                        }}
                      />
                      <div className="d-flex justify-content-center">
                        <CPagination
                          pages={totalPagesExpenses}
                          activePage={currentPageExpenses}
                          onActivePageChange={(i: number) =>
                            onPageChangeExpense(i)
                          }
                          className={totalPagesExpenses < 2 ? "d-none" : ""}
                        />
                      </div>
                    </div>
                    <CRow className={"mt-2"}>
                      <CCol className={"border border-dark"}>
                        <CRow className={"mt-2"}>
                          <CCol>
                            <h5>Valores Totales de selección:</h5>
                          </CCol>
                        </CRow>
                        <CRow className={"mt-2"}>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              Gasto total de la pagina visualizada:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {pageTotalPriceExpense
                                ? formatToCurrency(pageTotalPriceExpense, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              Gasto total todas las páginas:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalPriceExpense
                                ? formatToCurrency(queryTotalPriceExpense, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <p>
                              <small>
                                * Los valores de operaciones revertidas se
                                omiten de los totales
                              </small>
                            </p>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CTabPane>
                  <CTabPane data-tab="summary">
                    <CRow className={"mt-2"}>
                      <CCol>
                        <CRow className={"mt-2"}>
                          <CCol>
                            <h2>Resumen de Ganancia</h2>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            {generatingReport ? (
                              <CButton
                                color="danger"
                                className="fa-solid fa-file-pdf"
                                style={{ fontSize: "10px" }}
                                onClick={onReportClick}
                                disabled={!enableReportButton}
                              >
                                <Spinner
                                  animation="grow"
                                  style={{
                                    height: "17px",
                                    width: "17px",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginRight: "10px",
                                  }}
                                />{" "}
                                Generando...
                              </CButton>
                            ) : (
                              <CTooltip
                                content={
                                  !enableReportButton || loading
                                    ? "Debe seleccionar un rango máximo de 31 días"
                                    : "La generación de un reporte podria demorar unos minutos. Tenga paciencia"
                                }
                              >
                                <CButton
                                  color="danger"
                                  className="fa-solid fa-file-pdf"
                                  style={{ fontSize: "10px" }}
                                  onClick={onReportClick}
                                  disabled={!enableReportButton || loading}
                                >
                                  {" "}
                                  Reporte Totales
                                </CButton>
                              </CTooltip>
                            )}
                            <p>
                              La generación de reporte esta limitada a un rango
                              de 1 mes
                            </p>
                          </CCol>

                          <CCol>
                            {generatingReport ? (
                              <CButton
                                color="danger"
                                className="fa-solid fa-file-pdf"
                                style={{ fontSize: "10px" }}
                                onClick={onDailyReportClick}
                                disabled={!enableReportButton}
                              >
                                <Spinner
                                  animation="grow"
                                  style={{
                                    height: "17px",
                                    width: "17px",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginRight: "10px",
                                  }}
                                />{" "}
                                Generando...
                              </CButton>
                            ) : (
                              <CTooltip
                                content={
                                  !enableReportButton || loading
                                    ? "Debe seleccionar un rango máximo de 31 días"
                                    : "La generación de un reporte podria demorar unos minutos. Tenga paciencia"
                                }
                              >
                                <CButton
                                  color="danger"
                                  className="fa-solid fa-file-pdf"
                                  style={{ fontSize: "10px" }}
                                  onClick={onDailyReportClick}
                                  disabled={!enableReportButton || loading}
                                >
                                  {" "}
                                  Reporte por Día
                                </CButton>
                              </CTooltip>
                            )}
                            <p>
                              La generación de reporte esta limitada a un rango
                              de 1 mes
                            </p>
                          </CCol>
                        </CRow>
                        <CRow className={"mt-2"}>
                          <CCol>
                            <h4>
                              Sucursal:{" "}
                              <span className="font-weight-normal">
                                {selectedStore
                                  ? selectedStore.name
                                  : "No se seleccionó sucursal. Ganancia correspondera a todas las sucursales"}
                              </span>
                            </h4>
                            <p>
                              Obs: los valores y ganancias son calculados dentro
                              del rango de tiempo seleccionado. (Tener en cuenta
                              la seleccion tanto de fecha como de hora)
                            </p>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow>
                          <CCol md={6}>
                            <h5>VENTAS</h5>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg={5} xl={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              Valor total facturado:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalPrice
                                ? formatToCurrency(queryTotalPrice, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg={5} xl={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              Costo total de productos facturados:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalCost
                                ? formatToCurrency(queryTotalCost, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg={5} xl={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              Ganancia total por Ventas:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalProfit
                                ? formatToCurrency(queryTotalProfit, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg={5} xl={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              Valor total facturado en efectivo:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryCashOnlyTotalPrice
                                ? formatToCurrency(queryCashOnlyTotalPrice, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow>
                          <CCol md={3}>
                            <h5>GASTOS</h5>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg={5} xl={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              Gastos Resgistrados en sucursal:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalInStoreExpense
                                ? formatToCurrency(
                                    queryTotalInStoreExpense,
                                    PYG
                                  )
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg={5} xl={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              Gastos Resgistrados en fábrica:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalInFactoryExpense
                                ? formatToCurrency(
                                    queryTotalInFactoryExpense,
                                    PYG
                                  )
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg={5} xl={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              Gastos TOTALES:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalPriceExpense
                                ? formatToCurrency(queryTotalPriceExpense, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg={5} xl={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              Gastos Registrados en sucursal en Efectivo:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryCashTotalInStoreExpense
                                ? formatToCurrency(
                                    queryCashTotalInStoreExpense,
                                    PYG
                                  )
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className={"border border-dark"}>
                        <CRow className={"mt-2"}>
                          <CCol md={3}>
                            <h5>RESULTADO</h5>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              <CTooltip
                                content={"Ganancia generada por ventas"}
                              >
                                <CBadge
                                  shape="pill"
                                  color="info"
                                  className={"mr-1"}
                                >
                                  <i className="fa fa-info icon"></i>
                                </CBadge>
                              </CTooltip>
                              GANANCIA BRUTA (Ventas):
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {queryTotalProfit
                                ? formatToCurrency(queryTotalProfit, PYG)
                                : "-"}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              <CTooltip
                                content={
                                  "Valor total facturado eb efectivo menos gastos registrados en sucursal en efectivo"
                                }
                              >
                                <CBadge
                                  shape="pill"
                                  color="info"
                                  className={"mr-1"}
                                >
                                  <i className="fa fa-info icon"></i>
                                </CBadge>
                              </CTooltip>
                              GANANCIA en tienda (A DEPOSITAR solo efectivo):
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {formatToCurrency(
                                (queryCashOnlyTotalPrice || 0) -
                                  (queryCashTotalInStoreExpense || 0),
                                PYG
                              )}
                            </CLabel>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md={3}>
                            <CLabel className={"font-weight-bold mt-2"}>
                              <CTooltip
                                content={
                                  "Ganancia total por Ventas menos Gastos TOTALES"
                                }
                              >
                                <CBadge
                                  shape="pill"
                                  color="info"
                                  className={"mr-1"}
                                >
                                  <i className="fa fa-info icon"></i>
                                </CBadge>
                              </CTooltip>
                              GANANCIA NETA:
                            </CLabel>
                          </CCol>
                          <CCol md={3}>
                            <CLabel className={"mt-2 font-weight-bold"}>
                              {formatToCurrency(
                                (queryTotalProfit || 0) -
                                  (queryTotalPriceExpense || 0),
                                PYG
                              )}
                            </CLabel>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <SaleDetailModal
        show={showDetailModal}
        storeSale={storeSale}
        onCancel={onDetailModalCancel}
      ></SaleDetailModal>
    </>
  );
};

export default IncomeExpenseSummaryList;
